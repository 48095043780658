<template>
  <div
    v-show="showRoomsList"
    class="vac-rooms-container vac-app-border-r"
    :class="{ 'vac-rooms-container-full': isMobile }"
  >
    <v-dialog
      v-model="dialogSelectChannels"
      width="500"
    >
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Click Me
        </v-btn>
      </template> -->

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Select Channels
        </v-card-title>

        <v-divider></v-divider>
        <v-card-text>
          <v-card
            class="mx-auto"
            max-width="500"
          >
            <v-list>
              <v-list-item-group>
                <v-list-item
                  v-for="(item, i) in listInstances"
                  :key="i"
                  @click="selectInstanceData(item)"
                >
                  <v-list-item-icon>
                    <v-icon
                      large
                      :color="`${item.color}`"
                    >
                      {{ icons.mdiCircle }}
                    </v-icon>
                    <!-- <v-icon ></v-icon> -->
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="`( ${item.label} )-${item.phone_number}`"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
          <!-- <v-select
            v-model="selectedInstance"
            :items="listInstances"
            outlined
            hide-details
            required
            return-object
            attach
            item-text="label"
            :label="`Select Instances`"
            dense
          ></v-select> -->
        </v-card-text>

        <!--
         <v-select
          :items="listInstances"
          label="Outlined style"
          text='label'
          outlined
        ></v-select> -->

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            class="mr-3"
            @click="dialogSelectChannels = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <!-- <v-btn color="success" @click="openRoomSelected">
            {{ $t('save') }}
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <v-alert
      v-if="bannerAlertText && !loadingRooms "
      color="warning"
      class="pa-1 ma-2"
      prominent
      border="left"
      type="info"
    >
      <span class="subtitle-2">{{ bannerAlertText }}</span>
    </v-alert> -->

    <slot name="rooms-header" />

    <div class="vac-box-search height-calc">
      <!-- <v-tabs
        v-model="tabRoomFilter"
        centered
      >
        <v-tab
          v-for="(filter, index) in roomFilterList"
          :key="index"
          @click="changeSettingFilter(filter)"
        >
          <span>
            {{ filter }}
          </span>
        </v-tab>
      </v-tabs> -->
      <div class="scrollmenu text-center">
        <div
          v-for="(filter, filterIndex) in roomFilterList"
          :key="filterIndex + filter"
          :class="tabRoomFilter === filter ? 'filter-tools-active' : 'filter-tools'"
          @click="changeSettingFilter(filter)"
        >
          {{ filter }}
          <hr
            v-if="tabRoomFilter === filter"
            class="solid"
          />
        </div>
      </div>
    </div>

    <slot name="rooms-list-search">
      <rooms-search
        :rooms="rooms"
        :loading-rooms="loadingRooms"
        :text-messages="textMessages"
        :show-search="showSearch"
        :options-instances="optionsInstances"
        :show-add-room="showAddRoom"
        :show-filter-room="showFilterRoom"
        @search-room="searchRoom"
        @add-room="$emit('add-room')"
        @filter-room="filterRoom"
      >
        <template
          v-for="(i, name) in $scopedSlots"
          #[name]="data"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </rooms-search>
    </slot>

    <!-- <div v-show="filteredRooms.length === 0"> -->
    <loader :show="loadingRooms">
      <template
        v-for="(idx, name) in $scopedSlots"
        #[name]="data"
      >
        <slot
          :name="name"
          v-bind="data"
        />
      </template>
    </loader>
    <!-- </div> -->

    <!-- {{ rooms }} -->
    <div
      v-if="
        (!loadingRooms && !filteredRooms.length && !filteredRoomsGroups.length && !filteredRoomsMessages.length) ||
          roomsEmty
      "
      class="vac-rooms-empty"
    >
      <slot name="rooms-empty">
        {{ textMessages.ROOMS_EMPTY }}
      </slot>
    </div>
    <!-- <div
      v-if="!loadingRooms && filterChat === 'unread' && !showLoader && roomsEmty"
      class="vac-rooms-empty"
    >
      <slot name="rooms-empty">
        {{ textMessages.ROOMS_EMPTY }}
      </slot>
    </div> -->

    <div
      v-if="!loadingRooms"
      id="rooms-list"
      class="vac-room-list"
    >
    <!-- {{filteredRooms.length}} -->
      <div
        v-for="(fRoom, index) in filteredRooms"
        :id="fRoom.roomId"
        :key="index"
      >
        <div
          v-if="filterChat && filterChat === 'unread' && fRoom.unreadCount > 0 && !fRoom.lastMessage.fromMe"
          class="vac-room-item room-unread"
          :class="{ 'vac-room-selected': selectedRoomId === fRoom.roomId }"
          @click="openRoom(fRoom)"
        >
          <!-- <pre>{{fRoom}}</pre> -->
          <room-content
            :current-user-id="currentUserId"
            :room="fRoom"
            :text-formatting="textFormatting"
            :link-options="linkOptions"
            :text-messages="textMessages"
            :room-actions="roomActions"
            :all-contacts="allContacts"
            :all-contacts-firestore="allContactsFirestore"
            @room-action-handler="$emit('room-action-handler', $event)"
          >
            <template
              v-for="(i, name) in $scopedSlots"
              #[name]="data"
            >
              <slot
                :name="name"
                v-bind="data"
              />
            </template>
          </room-content>
        </div>
        <div
          v-if="filterChat && filterChat !== 'unread'"
          class="vac-room-item"
          :class="{ 'vac-room-selected': selectedRoomId === fRoom.roomId }"
          @click="openRoom(fRoom)"
        >
          <room-content
            :current-user-id="currentUserId"
            :room="fRoom"
            :text-formatting="textFormatting"
            :link-options="linkOptions"
            :text-messages="textMessages"
            :room-actions="roomActions"
            :all-contacts="allContacts"
            :all-contacts-firestore="allContactsFirestore"
            @room-action-handler="$emit('room-action-handler', $event)"
          >
            <template
              v-for="(i, name) in $scopedSlots"
              #[name]="data"
            >
              <slot
                :name="name"
                v-bind="data"
              />
            </template>
          </room-content>
        </div>
      </div>
      <!-- Start Search Chat -->
      <div
        v-if="
          currentSearch &&
            currentSearch.target &&
            currentSearch.target.value &&
            currentSearch.target.value.length > 1 &&
            filteredRoomsName.length
        "
      >
        Chat
      </div>
      <div
        v-if="
          currentSearch && currentSearch.target && currentSearch.target.value && currentSearch.target.value.length > 1
        "
      >
        <!-- <pre>{{filteredRoomsName}}</pre> -->
        <div
          v-for="fRoom in filteredRoomsName"
          :id="fRoom.roomId"
          :key="fRoom.roomId"
          class="vac-room-item"
          :class="{ 'vac-room-selected': selectedRoomId === fRoom.roomId }"
          @click="openRoom(fRoom, true)"
        >
          <room-content
            :current-user-id="currentUserId"
            :room="fRoom"
            :text-formatting="textFormatting"
            :link-options="linkOptions"
            :text-messages="textMessages"
            :room-actions="roomActions"
            :all-contacts="allContacts"
            :all-contacts-firestore="allContactsFirestore"
            @room-action-handler="$emit('room-action-handler', $event)"
          >
            <template
              v-for="(i, name) in $scopedSlots"
              #[name]="data"
            >
              <slot
                :name="name"
                v-bind="data"
              />
            </template>
          </room-content>
        </div>
      </div>
      <!-- End Search Chat -->

      <!-- Start Search Whatsapp Contact -->
      <div
        v-if="
          currentSearch &&
            currentSearch.target &&
            currentSearch.target.value &&
            currentSearch.target.value.length > 1 &&
            filteredRoomsGroups.length
        "
      >
        {{ $t('whatsappContacts') }}
      </div>
      <div
        v-if="
          currentSearch && currentSearch.target && currentSearch.target.value && currentSearch.target.value.length > 1
        "
      >
        <div
          v-for="fRoom in filteredRoomsGroups"
          :id="fRoom.roomId"
          :key="fRoom.roomId"
          class="vac-room-item"
          :class="{ 'vac-room-selected': selectedRoomId === fRoom.roomId }"
          @click="openRoom(fRoom, false ,true)"
        >
          <room-content
            :current-user-id="currentUserId"
            :room="fRoom"
            :text-formatting="textFormatting"
            :link-options="linkOptions"
            :text-messages="textMessages"
            :room-actions="roomActions"
            :all-contacts="allContacts"
            :all-contacts-firestore="allContactsFirestore"
            @room-action-handler="$emit('room-action-handler', $event)"
          >
            <template
              v-for="(i, name) in $scopedSlots"
              #[name]="data"
            >
              <slot
                :name="name"
                v-bind="data"
              />
            </template>
          </room-content>
        </div>
      </div>
      <div v-if="emtyRoomData && emtyRoomData.length">
        Contacts
        <!-- <pre>{{emtyRoomData}}</pre> -->
      </div>
      <div v-if="emtyRoomData && emtyRoomData.length">
        <div
          v-for="fRoom in emtyRoomData"
          :id="fRoom.phone_number"
          :key="fRoom.phone_number"
          class="vac-room-item"
          :class="{ 'vac-room-selected': selectedRoomId === fRoom.phone_number }"
          @click="openSelectChannels(fRoom)"
        >
          <room-content
            :current-user-id="currentUserId"
            :room="fRoom"
            :text-formatting="textFormatting"
            :link-options="linkOptions"
            :text-messages="textMessages"
            :room-actions="[]"
            :all-contacts="allContacts"
          >
            <template
              v-for="(i, name) in $scopedSlots"
              #[name]="data"
            >
              <slot
                :name="name"
                v-bind="data"
              />
            </template>
          </room-content>
        </div>
      </div>
      <!-- End Search Whatsapp Contact -->

      <!-- Start Search Messages -->
      <div
        v-if="
          currentSearch &&
            currentSearch.target &&
            currentSearch.target.value &&
            currentSearch.target.value.length > 1 &&
            filteredRoomsMessages.length
        "
      >
        Messages
      </div>
      <div
        v-if="
          currentSearch && currentSearch.target && currentSearch.target.value && currentSearch.target.value.length > 1
        "
      >
        <div
          v-for="fRoom in filteredRoomsMessages"
          :id="fRoom.roomId"
          :key="fRoom.roomId"
          class="vac-room-item"
          :class="{ 'vac-room-selected': selectedRoomId === fRoom.roomId }"
          @click="openRoom(fRoom, true)"
        >
          <room-content
            :current-user-id="currentUserId"
            :room="fRoom"
            :text-formatting="textFormatting"
            :link-options="linkOptions"
            :text-messages="textMessages"
            :room-actions="roomActions"
            :all-contacts="allContacts"
            :all-contacts-firestore="allContactsFirestore"
            @room-action-handler="$emit('room-action-handler', $event)"
          >
            <template
              v-for="(i, name) in $scopedSlots"
              #[name]="data"
            >
              <slot
                :name="name"
                v-bind="data"
              />
            </template>
          </room-content>
        </div>
      </div>
      <!-- End Search Messages -->

      <transition name="vac-fade-message">
        <div id="infinite-loader-rooms">
          <loader
            :show="showLoader"
            :infinite="true"
          >
            <template
              v-for="(idx, name) in $scopedSlots"
              #[name]="data"
            >
              <slot
                :name="name"
                v-bind="data"
              />
            </template>
          </loader>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import pouchDbMixin from '@/mixins/pouchDbMixin'
import eFormMixin from '@/mixins/eFormMixin'
import _debounce from 'lodash/debounce'
import { mdiCircle, mdiCommentMinusOutline } from '@mdi/js'

// import _orderBy from 'lodash/orderBy'

// import _uniqBy from 'lodash/uniqBy'

import Loader from '../../components/Loader/Loader'
import RoomContent from './RoomContent/RoomContent'
import RoomsSearch from './RoomsSearch/RoomsSearch'

// import { SET_FILTERED_ROOMS } from '../../../../store/auth/mutations'

export default {
  name: 'RoomsList',

  components: {
    Loader,
    RoomsSearch,
    RoomContent,
  },

  mixins: [pouchDbMixin, eFormMixin],

  props: {
    emtyRoomData: { type: Array, default: () => [] },
    currentUserId: { type: [String, Number], required: true },
    textMessages: { type: Object, required: true },
    showRoomsList: { type: Boolean, required: true },
    showSearch: { type: Boolean, required: true },
    showAddRoom: { type: Boolean, required: true },
    showFilterRoom: { type: Boolean, required: true },
    textFormatting: { type: Boolean, required: true },
    linkOptions: { type: Object, required: true },
    isMobile: { type: Boolean, required: true },
    rooms: { type: Array, required: true },
    optionsInstances: { type: Array, required: true },
    roomsOrigin: { type: Array, required: true },
    roomsGrouped: { type: Array, default: () => [] },
    roomsMessages: { type: Array, default: () => [] },
    roomsName: { type: Array, default: () => [] },
    currentOffset: { type: Number, default: 1 },
    loadingRooms: { type: Boolean, required: true },
    roomsLoaded: { type: Boolean, required: true },
    room: { type: Object, required: true },
    roomActions: { type: Array, required: true },
    allContacts: { type: Array, default: () => [] },
    allContactsFirestore: { type: Array, default: () => [] },
  },

  emits: [
    'add-room',
    'room-action-handler',
    'loading-more-rooms',
    'fetch-room',
    'fetch-rooms',
    'fetch-more-rooms',
    'filter-new-contacts',
    'change-rooms-loaded',
  ],

  data() {
    return {
      icons: {
        mdiCircle,
      },
      roomSelected: {},
      selectedInstance: {},
      dialogSelectChannels: false,
      roomsEmty: false,
      currentSearch: '',
      currentFilter: {
        filter: 'all',
        sort: 'newest',
      },
      filteringText: '',
      settingFilter: [],
      selectedSettingFilter: null,
      roomFilterList: ['all', 'assigned', 'unread'],
      roomFilter: 'all',
      tabRoomFilter: 0,
      filteredRooms: this.rooms || [],
      filteredRoomsGroups: this.roomsGrouped || [],
      filteredRoomsMessages: this.roomsMessages || [],
      filteredRoomsName: this.roomsName || [],
      observer: false,
      showLoader: false,
      loadingMoreRooms: true,
      selectedRoomId: '',

      // setting
      boldUnrepliedSetting: null,
      boldUnreplied: false,
      switchBold: false,

      // chatHeader
      flagHeader: false,
    }
  },
  computed: {
    listInstances() {
      const inst = this.$store.getters['auth/getListInstance']

      // //console.log(inst, `INI INSTANCE`)
      if (inst) {
        return inst.value
      }

      return []
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    getGlobalChannel() {
      return this.$store.getters['global/getGlobalChannel']
    },
    getFilterRoom() {
      return this.$store.getters['global/getFilterRoom']
    },

    //  checkParams() {
    //   return this.params
    // },
    bannerAlertText() {
      return this.$store.getters['inbox/getBannerAlertText']
    },
    allSettings() {
      return this.$store.getters['global/getSettings']
    },
    filterChat() {
      return this.$store.getters['global/getFilterChat']
    },
    getValueChannel() {
      return this.$store.getters['inboxNavbar/getValueChannel']
    },
    labelFilter() {
      return this.$store.getters['inbox/getLabelFilter']
    },
    chatHeaderPayload() {
      return this.$store.getters['global/getChatHeaderPayload']
    },
    selectedChatHeader() {
      return this.$store.getters['global/getSelectedChatHeader']
    },
  },
  watch: {
    roomsGrouped(newVal, oldVal) {
      // //console.log(newVal, this.getGlobalChannel, `INI GET GLOBAL`)
      this.filteredRoomsGroups = JSON.parse(JSON.stringify(newVal))
      if (this.getGlobalChannel && this.getGlobalChannel.value.instance_id) {
        this.filteredRoomsGroups = this.filteredRoomsGroups.filter(
          room => room.instance_id === this.getGlobalChannel.value.instance_id,
        )
      }
      if (this.currentSearch.target && this.currentSearch.target.value) {
        this.filteredRooms = []
      }
    },
    roomsMessages(newVal, oldVal) {
      this.filteredRoomsMessages = JSON.parse(JSON.stringify(newVal))
      if (this.getGlobalChannel && this.getGlobalChannel.value.instance_id) {
        this.filteredRoomsMessages = this.filteredRoomsMessages.filter(
          room => room.instance_id === this.getGlobalChannel.value.instance_id,
        )
      }
      if (this.currentSearch.target && this.currentSearch.target.value) {
        this.filteredRooms = []
      }
    },
    filterChat(varnew) {
      this.tabRoomFilter = this.roomFilterList.find(item => item == varnew)

      this.emitFetchRooms()
    },
    showRoomsList(varnew) {
      this.$eventBus.$emit('checkShowListContact', varnew)
    },
    rooms: {
      // yg dibawah bisa dihapus bila lebih 30 hari dari 10/08/2022
      // deep: true,
      handler(newVal, oldVal) {
        this.filteredRooms = JSON.parse(JSON.stringify(newVal))

        if (this.currentSearch.target && this.currentSearch.target.value) {
          this.filteredRooms = []
        } else if (this.filterChat === 'assigned') {
          // //console.log(66, this.filteredRooms)
          this.filteredRooms = this.filteredRooms.filter(room => {
            return room.assign_to && room.assign_to.email === this.user.email
          })

          // //console.log(66, this.filteredRooms)
        } else if (this.filterChat === 'unreplied') {
          this.filteredRooms = this.filteredRooms.filter(room => room.unreplied)
        } else if (this.filterChat === 'all') {
          if (this.user.chat_mode === 'Read and Write to assigned only') {
            this.filteredRooms = this.filteredRooms.filter(
              room => !room.assign_to || (room.assign_to && room.assign_to.email === this.user.email),
            )
          }
        }

        if (this.getGlobalChannel) {
          this.filteredRoomsGroups = JSON.parse(JSON.stringify(this.roomsGrouped))
          this.filteredRoomsMessages = JSON.parse(JSON.stringify(this.roomsMessages))
          this.filteredRoomsName = JSON.parse(JSON.stringify(this.roomsName))
          if (!this.getGlobalChannel.value.all_sender) {
            this.filteredRoomsGroups = this.filteredRoomsGroups.filter(
              room => room.instance_id === this.getGlobalChannel.value.instance_id,
            )
            this.filteredRoomsMessages = this.filteredRoomsMessages.filter(
              room => room.instance_id === this.getGlobalChannel.value.instance_id,
            )
            this.filteredRoomsName = this.filteredRoomsName.filter(
              room => room.instance_id === this.getGlobalChannel.value.instance_id,
            )
          }
        }

        // //console.log(this.filteredRoomsGroups, "filteredRoomsGroups")
        // //console.log(this.roomsMessages, "roomsMessages")
        // //console.log(this.filteredRoomsName, "filteredRoomsName")

        if (newVal.length !== oldVal.length && this.roomsLoaded) {
          this.loadingMoreRooms = false
        }

        // this.$store.commit('rooms/SET_FILTERED_ROOMS', this.filteredRooms)
        const roomUnread = document.getElementsByClassName('room-unread')
        if (roomUnread) {
          this.roomsEmty = false
        }
      },
    },
    loadingRooms(val) {
      //console.log(706, val, `INI LOADING ROOMS`)
      if (!val) {
        setTimeout(() => this.initIntersectionObserver()).then(() => (this.showLoader = false))
      }
    },
    loadingMoreRooms(val) {
      //console.log(66, 'loadingMoreRooms value is', val)
      this.$emit('loading-more-rooms', val)

      // this.$eventBus.$emit('loadingMoreRooms')
    },
    roomsLoaded: {
      immediate: true,
      handler(val) {
        //console.log(720, val, this.observer, this.loadingRooms, this.showLoader, `INI VAL NYA`)
        if (val) {
          this.loadingMoreRooms = false

          this.showLoader = false
          if (!this.loadingRooms) {
            this.showLoader = false
          }
        } else {
          // //console.log('masuk else terus')
          setTimeout(() => this.initIntersectionObserver())
          this.loadMoreRooms()

          // this.loadingMoreRooms = true
          // this.showLoader = true
        }
      },
    },
    room: {
      immediate: true,
      handler(val) {
        if (val && !this.isMobile) this.selectedRoomId = val.roomId
      },
    },
    chatHeaderPayload(varnew) {
      //console.log(varnew, 'INID VARNEW1')
      if (varnew !== null) {
        this.openRoomHeader(varnew)

        // this.$store.commit('global/setChatHeaderPayload', null)
      }
    },
    selectedChatHeader(varnew) {
      if (varnew.status) {
        this.selectedRoomId = varnew.roomId
        this.flagHeader = true
        this.$store.commit('global/setSelectedChatHeader', {
          status: false,
          roomId: '',
        })
      }
    },
  },

  async mounted() {
    this.$eventBus.$emit('checkShowListContact', this.showRoomsList)

    this.tabRoomFilter = this.roomFilterList.find(item => item == this.filterChat)

    // //console.log(this.roomsLoaded, `INI ROOMS DI KOMPONENT`)

    // await this.connectSettings()
    // await this.getSettings(true)
  },
  methods: {
    selectInstanceData(data) {
      //console.log('selectInstanceData is invoked')
      this.selectedInstance = data
      this.openRoomSelected(data)

      // //console.log(data)
    },

    openSelectChannels(data) {
      //console.log('openSelectChannels is invoked')
      this.dialogSelectChannels = true

      this.roomSelected = data
    },
    openRoomSelected() {
      //console.log('openRoomSelected is invoked')

      // this.selectedInstance
      const dataRoom = JSON.parse(JSON.stringify(this.roomSelected))
      dataRoom.instance_id = this.selectedInstance.instance_id
      dataRoom.roomId = `${this.selectedInstance.instance_id}-${dataRoom.phone_number}`
      dataRoom.roomOwnerId = this.selectedInstance.label_server
      dataRoom.roomOwnerName = this.selectedInstance.phone_number
      dataRoom.instance = this.selectedInstance

      this.dialogSelectChannels = false

      // this.roomSelected = {}
      this.openRoom(dataRoom, false, true)
    },
    changeSettingFilter(value) {
      //console.log('changeSettingFilter is invoked')
      let filter = value
      if (filter === 'closed') {
        filter = 'close'
      }
      this.filteringText = filter
      this.tabRoomFilter = this.roomFilterList.find(item => item == filter)
      this.$store.commit('inboxNavbar/setValueContact', filter)
      this.$store.commit('global/setFilterChat', filter)

      // this.resultFilteredRoom()
      this.emitFetchRooms()
    },
    initIntersectionObserver() {
      //console.log('initIntersectionObserver is invoked')
      if (this.observer) {
        this.showLoader = true
        this.observer.disconnect()

        // //console.log(true, `MASUK SINI`)
      }

      const loader = document.getElementById('infinite-loader-rooms')

      // //console.log(loader, `INI LOADER COMPONENT`)
      if (loader) {
        const options = {
          root: document.getElementById('rooms-list'),
          rootMargin: '60px',
          threshold: 0,
        }

        this.observer = new IntersectionObserver(entries => {
          // //console.log(entries, `INI ENTRIES`)
          if (entries[0].isIntersecting) {
            // this.showLoader = true
            this.loadMoreRooms()
          }
        }, options)

        this.observer.observe(loader)

        // this.showLoader = true
      }

      // //console.log(this.observer, `INI OBS`)
    },
    getContactsNameRoom(phoneNumber, roomName) {
      //console.log('getContactsNameRoom is invoked')
      if (this.allContactsFirestore) {
        const foundContact = this.allContactsFirestore.find(x => x.phone_number === phoneNumber)

        if (foundContact) {
          return foundContact.name
        }
      }
      if (this.allContacts) {
        const foundContact = this.allContacts.find(x => x.phone_number === phoneNumber)

        if (foundContact) {
          return foundContact.name
        }
      }

      return roomName
    },
    searchRoom(ev) {
      //console.log('searchRoom is invoked')
      this.updateValue(ev)
    },
    updateValue: _debounce(function (ev) {
      //console.log('updateValue is invoked')
      this.currentSearch = ev
      this.$store.commit('global/setSearch', ev)

      // if (this.currentSearch.target && this.currentSearch.target.value) {
      //   this.$emit('filter-new-contacts', this.currentSearch.target.value)
      // }

      this.emitFetchRooms()
    }, 1000),
    filterRoom(ev) {
      //console.log('filterRoom is invoked')
      this.currentFilter = ev

      // this.resultFilteredRoom()
      this.emitFetchRooms()
    },
    emitFetchRooms() {
      //console.log('emitFetchRooms is invoked')
      this.$emit('fetch-rooms', {
        currentFilter: this.currentFilter || '',
        currentSearch: this.currentSearch || '',
        labelFilter: this.labelFilter || '',
        filterChat: this.filterChat || '',
        getValueChannel: this.getValueChannel || '',
      })
    },
    openRoom(room, fromMsg = false, fromContactData = false) {
      //console.log('openRoom is invoked')
      if (room.roomId === this.room.roomId && !this.isMobile && !this.flagHeader) return
      if (!this.isMobile) this.selectedRoomId = room.roomId
      if (fromContactData) {
        this.$emit('fetch-room', { room }, fromMsg, false, true)
      } else {
        this.$emit('fetch-room', { room }, fromMsg)
      }
      this.flagHeader = false
    },
    async openRoomHeader(roomId, fromMsg = false, fromContactData = false) {
      const room = this.rooms.find(el => el.roomId === roomId)
      //console.log(roomId, room, 'INID SELEC')

      if (room) {
        this.selectedRoomId = room.roomId
        //console.log(this.selectedRoomId, 'INID SELECTED')
        this.$emit('fetch-room', { room }, fromMsg)
      } else {
        const oneRoomFromPg = await this.$store.dispatch('rooms/getOneRoomsPostgres', {
          sub_id: this.user.sub_id,
          roomId,
        })
        this.$emit('fetch-room', { room: oneRoomFromPg.data }, fromMsg)
      }
    },
    loadMoreRooms() {
      //console.log('loadMoreRooms is invoked')

      // //console.log(
      //   this.roomsLoaded,
      //   this.currentOffset,
      //   this.loadingRooms,
      //   this.loadingMoreRooms,
      //   `INI DI LOAD MORE ROOMS`,
      // )
      // if (this.loadingMoreRooms) return

      // if (this.roomsLoaded && !this.loadingRooms) {
      //   this.loadingMoreRooms = false
      //   this.showLoader = false

      //   return
      // }

      // if (this.currentOffset !== 0) {
      // } else {
      //   return
      // }
      //console.log(this.currentOffset, `INI OFFSET NYA`)
      if (this.currentOffset === 0) {
        this.showLoader = false
        this.loadingMoreRooms = false
      } else {
        // //console.log(this.currentOffset, `INI OFFSET`)

        this.loadingMoreRooms = true
        this.showLoader = true
        this.$emit('fetch-more-rooms')
        this.unreadClearLoader(this.currentOffset)
      }

      if (this.labelFilter) {
        this.showLoader = false
      }
    },
    unreadClearLoader() {
      //console.log('unreadClearLoader is invoked')
      setTimeout(() => {
        const roomUnread = document.getElementsByClassName('room-unread')

        // let unreadNull =  this.filteredRooms.filter(fRoom=> fRoom.unreadCount > 0 && !fRoom.lastMessage.fromMe && fRoom.assign_to === null )
        // let unread =  this.filteredRooms.filter(fRoom=> fRoom.unreadCount > 0 && !fRoom.lastMessage.fromMe && fRoom.assign_to && fRoom.assign_to.email === this.user.email)
        if (this.filterChat === 'unread' && this.showLoader === true && !roomUnread.length) {
          this.roomsEmty = true
          this.showLoader = false
          this.loadingMoreRooms = false
        }
      }, 10000)
    },
    async changeValueBold() {
      //console.log('changeValueBold is invoked')
      this.switchBold = true
      this.boldUnrepliedSetting.value = this.boldUnreplied
      await this.updateSettings(this.boldUnrepliedSetting, async x => {
        if (x === false) {
          this.boldUnreplied = !this.boldUnreplied
        }
        this.$store.dispatch('global/setBoldUnreplied', this.boldUnreplied)
        this.switchBold = false
      })
    },
    async getSettings(fromServer = false) {
      //console.log('getSettings is invoked')
      if (fromServer) {
        await this.loadSettings()
      }

      const res = await this.mappingSettingChannels(this.allSettings)

      if (res.boldUnrepliedSetting) {
        this.boldUnrepliedSetting = res.boldUnrepliedSetting
        this.boldUnreplied = this.boldUnrepliedSetting.value
        this.$store.dispatch('global/setBoldUnreplied', this.boldUnreplied)
      } else {
        await this.updateSettings(
          { _id: 'bold_unreplied', key: 'bold_unreplied', value: this.boldUnreplied },
          async x => {
            this.boldUnrepliedSetting = await this.loadSettingsById(x.id)
            this.boldUnreplied = this.boldUnrepliedSetting.value
            this.$store.dispatch('global/setBoldUnreplied', this.boldUnreplied)
          },
        )
      }
    },
  },
}
</script>
<style scoped>
.vac-box-search {
  position: sticky;
  display: flex;
  align-items: center;
  height: 64px;
  padding: 0 15px;
}

.vac-room-group {
  border-radius: 8px;
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  padding: 0 14px;
  position: relative;
  min-height: 30px;
  cursor: auto;
  transition: background-color 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  font-weight: 500;
}

div.scrollmenu {
  text-transform: uppercase;
  color: grey;
  margin: 0 auto;
  overflow: auto;
  text-align: center;
  white-space: nowrap;
  -ms-overflow-style: none /* IE and Edge */ !important;
  scrollbar-width: none /* Firefox */ !important;
}

div.scrollmenu::-webkit-scrollbar {
  display: none;
}

div.scrollmenu .filter-tools-active {
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
  display: inline-block;
  color: #90e3dc;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
}

div.scrollmenu .filter-tools {
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
  display: inline-block;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
}

@media (max-width: 900px) {
  div.scrollmenu .filter-tools-active {
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
    display: inline-block;
    color: #90e3dc;
    font-size: 18px;
    text-align: center;
    text-decoration: none;
  }

  div.scrollmenu .filter-tools {
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
    display: inline-block;
    font-size: 18px;
    text-align: center;
    text-decoration: none;
  }

  hr.solid {
    border-top: 2px solid #90e3dc;
  }
}

hr.solid {
  border-top: 1px solid #90e3dc;
}

.height-calc {
  height: 36px !important;
}
</style>
