<template>
  <v-dialog v-model="dialog.addMessageTEmplate" width="960px" persistent>
    <v-card>
      <v-snackbar
        v-model="showSnackbarCopyNotSupported"
        color="error"
        :timeout="2000"
        :top="true"
        style="z-index: 5 !important"
      >
        {{ $t('broadcasts.linkAlert') }}
        <template #action="{ attrs }">
          <v-btn text v-bind="attrs" @click="closeShowSnackbarCopyNotSupported()">
            {{ $t('close') }}
          </v-btn>
        </template>
      </v-snackbar>

      <v-card-title class="my-2">
        {{ status_message_template === 'add' ? $t('create') : $t('edit') }}
        {{ $t('broadcasts.messageTemplate') }}
      </v-card-title>
      <v-divider />
      <div class="height-dialog pt-5">
        <v-card-text>
          <v-form ref="form-add-template-parent" lazy-validation>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="formAddTemplate.template_name"
                  required
                  :label="`${$t('messageTemplates.templateName')} *`"
                  outlined
                  hide-details
                  class="mb-3"
                  :disabled="showTemplate"
                  :rules="[v => !!v || 'item required!']"
                ></v-text-field>
              </v-col>
              <!-- <v-col
                cols="12"
                md="4"
              >
                <v-select
                  v-model="contactList"
                  :items="showTemplate ? [contactList] : contactListItems"
                  :label="$t('broadcasts.contactSource')"
                  item-text="text"
                  outlined
                  required
                  :rules="[v => !!v.text || 'category required!']"
                  return-object
                  hide-details
                  class="mb-3"
                  :disabled="showTemplate"
                  @change="reloadMustache()"
                ></v-select>
              </v-col> -->
              <v-col cols="12" md="6">
                <v-select
                  v-model="formAddTemplate.category"
                  :items="showTemplate ? [formAddTemplate.category] : listCategory"
                  :label="`${$t('messageTemplates.category')} *`"
                  item-text="name"
                  outlined
                  return-object
                  required
                  :rules="[v => !!v || 'item required!']"
                  item-value="id"
                  hide-details
                  class="mb-3"
                  :disabled="showTemplate"
                  @change="selectCategory"
                >
                  <!-- :rules="[required]" -->
                  <template #item="{ item, on, attr }">
                    <div v-if="item.id !== 'add-category-option'">
                      <v-list-item-content v-on="on" v-bind="attr">
                        {{ item.name }}
                      </v-list-item-content>
                    </div>
                    <v-list-item v-else :class="{ 'add-category-option': true }" v-on="on" v-bind="attr">
                      <v-list-item-content>
                        <span style="color: #1f979e">{{ item.name }}</span>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
                <!-- <v-select
                  v-model="formAddTemplate.category"
                  :items="showTemplate ? [formAddTemplate.category] : listCategory"
                  :label="`${$t('messageTemplates.category')} *`"
                  item-text="name"
                  outlined
                  return-object
                  :rules="[required]"
                  required
                  item-value="id"
                  hide-details
                  class="mb-3"
                  :disabled="showTemplate"
                >
                  <template #item="{ item, on, attrs }">
                    <div v-if="item.id !== 'add-category-option'">
                      <v-list-item-content v-on="on" v-bind="attrs">
                        {{ item.name }}
                      </v-list-item-content>
                    </div>
                    <v-list-item
                      v-else
                      :class="{ 'add-category-option': true }"
                      v-on="on"
                      v-bind="attrs"
                      @click.prevent="showCategory"
                    >
                      <v-list-item-content>
                        <span style="color: #1f979e">{{ item.name }}</span>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select> -->
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-tabs v-model="tab" show-arrows>
          <v-tab>{{ $t('messageTemplates.openingChat') }}</v-tab>
          <!-- <v-tab>{{ $t('messageTemplates.onReply') }}</v-tab> -->
        </v-tabs>

        <!-- ///////////////////////////////////////////////////////////////////////////////////////////////
////////////////////// MESSAGE FORM ADD TEMPLATE BELUM JALAN :3 ///////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////// -->

        <v-card-text>
          <v-tabs-items v-model="tab">
            <!-- On Sending -->
            <v-tab-item>
              <v-card flat height="auto" class="mb-8">
                <v-col cols="12">
                  <v-card-text>
                    <v-form ref="form-add-template" lazy-validation>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-textarea
                            id="messageText"
                            v-model="formAddTemplate.message"
                            :rules="[v => !!v || 'You must agree to continue!']"
                            required
                            :label="`${$t('message')} *`"
                            outlined
                            rows="7"
                            class="mb-3"
                            hide-details
                            :disabled="showTemplate"
                          ></v-textarea>
                          <v-card-actions>
                            <!-- <v-icon
                              class="vac-svg-button mr-2 ml-n4"
                              style="transform: matrix(-0.82, -0.57, -0.57, 0.82, 0, 0); !important;"
                              color="#5C9DDE"
                              @click="launchFilePicker()"
                              >{{ icons.mdiPaperclip }}</v-icon
                            >
                            <v-icon class="vac-svg-button" color="#5C9DDE" @click="openFileManagerMsg()">{{
                              icons.mdiBullhornOutline
                            }}</v-icon> -->

                            <input
                              ref="file"
                              type="file"
                              multiple
                              style="display: none"
                              @change="saveFile($event.target.files, 'filePicker')"
                            />

                            <div class="vac-svg-button" ref="triggerIcon" @click.stop="handleOpenAttachment">
                              <slot name="paperclip-icon">
                                <!-- <svg-icon name="paperclip" /> -->
                                <v-icon
                                  style="transform: matrix(-0.82, -0.57, -0.57, 0.82, 0, 0); !important;"
                                  color="#5C9DDE"
                                >
                                  {{ icons.mdiPaperclip }}
                                </v-icon>
                              </slot>
                            </div>
                            <transition name="vac-slide-left" v-if="openAttachment">
                              <div class="vac-menu-options" style="top: 200px; left: 90px; max-width: fit-content">
                                <div class="vac-menu-list">
                                  <div v-for="(item, index) in openAttachmentItems" :key="index">
                                    <div class="vac-menu-item" @click="attachmentAction(item.action)">
                                      {{ item.title }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </transition>
                          </v-card-actions>
                          <v-row class="mt-10">
                            <div v-if="isLoadingImage" class="loading-indicator">
                              <v-progress-circular :size="30" color="primary" indeterminate></v-progress-circular>
                            </div>
                            <v-col v-if="!isLoadingImage" v-for="(el, idx) in formAddTemplate.attachments" :key="idx">
                              <v-img :src="el.preview" width="120px" position="relative">
                                <v-icon position="absolute" top="0" color="#000000" @click="removeImage(idx)">
                                  {{ icons.mdiClose }}
                                </v-icon></v-img
                              >
                              <span class="message-file-name">{{ el.filename }}</span>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col v-if="dialog.addMessageTEmplate === true" cols="12" md="6">
                          <div v-for="(button, index) of getButtons" :key="index" class="mb-2">
                            <v-btn class="mt-1" color="primary">
                              {{ button.label }}
                            </v-btn>
                            <v-btn icon small @click="removeButton(index)">
                              <v-icon size="20">
                                {{ icons.mdiClose }}
                              </v-icon>
                            </v-btn>
                          </div>
                          <v-expansion-panels class="mb-2" accordion>
                            <v-expansion-panel>
                              <v-expansion-panel-header> Emoji 😃 </v-expansion-panel-header>
                              <v-expansion-panel-content class="align-center align-content-center px-0">
                                <emoji-picker
                                  style="width: 100%"
                                  :class="isDark ? 'dark' : 'light'"
                                  @emoji-click="
                                    e => {
                                      addEmoji(e)
                                    }
                                  "
                                ></emoji-picker>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                              <v-expansion-panel-header> Ekstra Field </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-chip
                                  v-if="!showTemplate"
                                  small
                                  block
                                  color="primary"
                                  class="text-capitalize mr-1"
                                  @click="addText(`{{phone_number}}`)"
                                >
                                  phone number
                                </v-chip>
                                <v-chip
                                  v-if="!showTemplate"
                                  small
                                  block
                                  color="error"
                                  class="text-capitalize mr-1"
                                  @click="addText(`{{name}}`)"
                                >
                                  Name
                                </v-chip>
                                <v-chip
                                  v-for="item in mustacheList"
                                  :key="item.value"
                                  block
                                  small
                                  color="primary"
                                  class="text-capitalize mr-1"
                                  @click="addText(`{{${item.field}}}`)"
                                >
                                  {{ item.name }}
                                </v-chip>
                                <v-select
                                  v-model="extraFieldChoosed"
                                  :items="dataExtraFieldProps.contacts_list"
                                  label="Extra Field"
                                  dense
                                  hide-details
                                  item-text="list_name"
                                  class="mt-4 mb-3"
                                  item-value="id"
                                  @change="addExtrafield"
                                ></v-select>
                                <v-chip
                                  v-for="item in extraFieldData"
                                  :key="item.value"
                                  block
                                  small
                                  dense
                                  color="primary"
                                  class="text-capitalize mr-1"
                                  @click="addText(`{{${item.name}}}`)"
                                >
                                  {{ item.name }}
                                </v-chip>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                            <!-- <v-expansion-panel>
                              <v-expansion-panel-header> Link Button </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-text-field
                                  v-model="labelLink"
                                  required
                                  label="Label"
                                  outlined
                                  dense
                                  hide-details
                                  class="mb-3"
                                ></v-text-field>
                                <v-text-field
                                  v-model="valueLink"
                                  required
                                  label="Link URL"
                                  outlined
                                  dense
                                  hide-details
                                  class="mb-3"
                                ></v-text-field>
                                <v-btn color="primary" @click="addButtonLink">
                                  {{ $t('add') }}
                                </v-btn>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                              <v-expansion-panel-header> Call Button </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-text-field
                                  v-model="labelCall"
                                  required
                                  label="Label"
                                  outlined
                                  dense
                                  hide-details
                                  class="mb-3"
                                ></v-text-field>
                                <v-text-field
                                  v-model="valueCall"
                                  required
                                  label="Phone Number"
                                  outlined
                                  type="number"
                                  dense
                                  hide-details
                                  class="mb-3"
                                ></v-text-field>
                                <v-btn color="primary" @click="addButtonCall">
                                  {{ $t('add') }}
                                </v-btn>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                              <v-expansion-panel-header> Reply Button </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-text-field
                                  v-model="labelReply"
                                  required
                                  label="Reply Text"
                                  outlined
                                  dense
                                  hide-details
                                  class="mb-3"
                                ></v-text-field>
                                <v-btn color="primary" @click="addButtonReply">
                                  {{ $t('add') }}
                                </v-btn>
                              </v-expansion-panel-content>
                            </v-expansion-panel> -->
                            <!-- <v-expansion-panel>
                                <v-expansion-panel-header> Link Generator </v-expansion-panel-header>
                                <v-expansion-panel-content class="align-center align-content-center px-0">
                                  <v-chip
                                    v-for="tracker in dataShopLink"
                                    small
                                    block
                                    class="mr-1"
                                    @click="addGeneratorLink(tracker.url)"
                                    :key="tracker.slug"
                                  >
                                    {{ tracker.slug }}
                                  </v-chip>
                                </v-expansion-panel-content>
                              </v-expansion-panel> -->
                            <!-- <v-expansion-panel>
                                <v-expansion-panel-header> Link Tracker </v-expansion-panel-header>
                                <v-expansion-panel-content class="align-center align-content-center px-0">
                                  <v-chip
                                    v-for="tracker in dataTracker"
                                    small
                                    block
                                    class="mr-1"
                                    @click="addTrackerLink(tracker.slug)"
                                    :key="tracker.slug"
                                  >
                                    {{ tracker.slug }}
                                  </v-chip>
                                </v-expansion-panel-content>
                              </v-expansion-panel> -->
                          </v-expansion-panels>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-col>
              </v-card>
            </v-tab-item>
            <!-- <v-tab-item>
                <v-card>
                  <!-- On reply -->
            <!-- <v-col cols="12">
                    <v-card-text>
                      <v-form ref="form-add-template-reply">
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-textarea
                              v-model="on_reply_message.message"
                              :rules="[v => !!v || 'You must agree to continue!']"
                              required
                              :label="$t('message')"
                              outlined
                              rows="9"
                              class="mb-3"
                              hide-details
                              :disabled="showTemplate"
                            ></v-textarea>
                            <v-chip
                              v-if="!showTemplate"
                              small
                              block
                              color="primary"
                              class="text-capitalize mr-1"
                              @click="on_reply_message.message += `{{phone_number}}`"
                            >
                              phone number
                            </v-chip>
                            <v-chip
                              v-if="!showTemplate"
                              small
                              block
                              color="error"
                              class="text-capitalize mr-1"
                              @click="on_reply_message.message += `{{name}}`"
                            >
                              Name
                            </v-chip>
                            <v-chip
                              v-for="item in mustacheList"
                              :key="item.value"
                              block
                              small
                              color="primary"
                              class="text-capitalize mr-1"
                              @click="on_reply_message.message += `{{${item.field}}}`"
                            >
                              {{ item.name }}
                            </v-chip>
                          </v-col>
                          <v-col v-if="dialog.addMessageTEmplate === true" cols="12" md="6">
                            <uppyUploader
                              ref="uppy-data-onreply"
                              :key="'onreply-' + dataIndexOnreply"
                              :data-index="'onreply-' + dataIndexOnreply"
                              :status="status_message_template"
                              :data-uppy="propsUppyUploaderReply"
                              :config="uppySettings"
                              :dashboard-config="uppyDashboardSettings"
                            />
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-card-text>
                  </v-col>
                </v-card>
              </v-tab-item> -->
          </v-tabs-items>
        </v-card-text>
      </div>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="#ffeeee" class="" @click="closeDialog">
          <span class="error--text">{{ $t('cancel') }}</span>
        </v-btn>

        <v-btn v-if="status_message_template === 'add'" color="success" @click="saveMessageTemplate()">
          {{ $t('save') }}
        </v-btn>
        <v-btn v-else color="success" @click="editMessageTemplate()">
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import uppyUploader from '@/components/UppyUploader.vue'
import { fileManagerModal } from '@/helpers/mini-file-manager'
import { default as clientPocketBase } from '@/helpers/pocketbase'
import PouchDb from '@/mixins/pouchDbMixin'
import Time from '@/mixins/time'
import fileManagerOptions from '@/views/MyAssets/options/optionsFileManager.js'
import '@/views/MyAssets/style.css'
import useAppConfig from '@core/@app-config/useAppConfig'
import {
  mdiAccountPlusOutline,
  mdiBullhornOutline,
  mdiClose,
  mdiPaperclip,
  mdiPencil,
  mdiPlus,
  mdiTrashCan,
} from '@mdi/js'
import 'emoji-picker-element'
import insertTextAtCursor from 'insert-text-at-cursor'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    uppyUploader,
  },
  mixins: [PouchDb, Time],
  props: ['updateCategory'],
  setup() {
    const { isDark } = useAppConfig()

    return {
      isDark,
    }
  },
  data() {
    return {
      openAttachment: false,
      openAttachmentItems: [
        { title: 'My Asset', action: 'myasset' },
        { title: 'Upload Files', action: 'filepicker' },
      ],
      tab: '',
      required: this.required,
      headers: [
        { text: '#', value: 'action' },
        { text: 'Template Name', value: 'templateName' },
        { text: 'Category', value: 'category' },
        { text: 'Quick Reply', value: 'quickReply' },
        { text: 'Last Update', value: 'lastUpdate' },
      ],
      headersCategory: [
        { text: 'Name', value: 'name' },
        { text: 'Action', value: 'action' },
      ],
      looading: {
        category: false,
      },
      showSnackbarCopyNotSupported: false,
      labelLink: '',
      valueLink: '',
      labelCall: '',
      valueCall: '',
      labelReply: '',
      valueReply: '',

      // uppy setting
      uppySettings: {
        restrictions: {
          maxFileSize: 100 * 1024 * 1024,
          allowedFileTypes: ['.jpg', '.jpeg', '.png', '.gif'],
        },
      },
      uppyDashboardSettings: {
        note: ' Image Only',
      },

      // list
      listTemplate: [],
      listExtraField: [],
      listCategory: [],

      // icon
      icons: {
        mdiAccountPlusOutline,
        mdiPlus,
        mdiTrashCan,
        mdiClose,
        mdiBullhornOutline,
        mdiPaperclip,
        mdiPencil,
      },
      dialog: {
        addMessageTEmplate: false,
        category: false,
        categoryDelete: false,
      },
      category: ['promotion', 'Notification'],
      selectTable: [],
      selectTableCategory: [],
      indexCategory: null,
      filter: '',
      uppy: null,

      // category
      categoryName: '',
      extrafieldName: '',

      formExtraField: [],
      formAddTemplate: {
        template_name: '',
        category: {},
        language: '',
        message: '',
        quick_reply: false,
        link_attachment: [],
        last_update: '',
        attachments: [],
        contact_list: '',
        id: '',
        on_reply: false,
        on_reply_message: {},
        listButton: [],
        buttonHeader: '',
        buttonFooter: '',
      },

      on_reply_message: {
        message: '',
        attachments: [],
      },
      dataExtraFieldProps: '',
      extraFieldChoosed: 'null',
      extraFieldData: [],

      status_message_template: 'add',
      propsUppyUploader: null,
      propsUppyUploaderReply: null,
      dataIndex: 0,
      dataIndexOnreply: 0,
      disableExtraField: false,
      contactSourceIdx: 0,
      mustacheList: [],
      mustacheAll: {},
      contactListItems: [],
      contactListAll: [],
      contactList: { text: '', value: '' },
      showTemplate: false,
      linkWaRediurect: '',
      photoLinks: [],
      isLoadingImage: false,
    }
  },
  watch: {
    formAddTemplate() {
      //console.log(this.formAddTemplate, 'form add template nyund')
    },
    'dialog.addMessageTEmplate': function (newValue, oldValue) {
      if (!newValue) {
        this.resetForm()
      }
    },
    updateCategory() {
      if (this.updateCategory) {
        this.getDataCategory()
        this.$emit('update-category')
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
    getButtons() {
      return this.formAddTemplate.listButton
    },
  },
  async mounted() {
    await clientPocketBase.collection('crm_message_template_categories').subscribe('*', e => this.getDataCategory())

    // this.linkWaRediurect = process.env.VUE_APP_URL_WA_LINK
    // const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(
    //   /['"]+/g,
    //   '',
    // )}superwa/couchdb/message-templates/get-contact-list`
    // const obj = {
    //   subId: this.user.sub_id,
    // }

    // if (this.serverConnectionStatus) {
    //   this.uppyDashboardSettings.disabled = false
    //   await this.$axiosLimit
    //     .post(endpoint, obj, { headers: { Authorization: `Bearer ${this.user.token}` } })
    //     .then(res => {
    //       const tempContactList = res.data.contacts_list.map(el => {
    //         const newEl = { text: el.list_name, value: el.id }

    //         return newEl
    //       })
    //       this.mustacheAll = res.data.extra_field

    //       this.contactListItems = [{ text: 'Whatsapp Contact', value: 'whatsapp' }, ...tempContactList]
    //     })
    //     .catch(error => {
    //       console.log(error)
    //     })
    // } else {
    //   this.uppyDashboardSettings.disabled = true
    // }
    // const dataContactsFromApi = await this.$store.dispatch('broadcast/getContacts', {
    //   subId: this.user.sub_id,
    //   token: this.user.token,
    // })
    // this.dataExtraFieldProps = dataContactsFromApi.data
  },
  methods: {
    attachmentAction(action) {
      switch (action) {
        case 'myasset':
          this.openFileManagerMsg()
          this.openAttachment = false
          break
        case 'filepicker':
          this.launchFilePicker()
          this.openAttachment = false
          break
        default:
          break
      }
    },
    handleOpenAttachment() {
      this.openAttachment = !this.openAttachment
    },
    selectCategory(item) {
      if (item.id === 'add-category-option') {
        this.formAddTemplate.category = {}
        this.$emit('show-category')
      } else {
        this.formAddTemplate.category = item
      }
    },
    addExtrafield() {
      this.extraFieldData = this.dataExtraFieldProps.extra_field[this.extraFieldChoosed]
    },
    removeImage(idx) {
      this.formAddTemplate.attachments = this.formAddTemplate.attachments.filter((element, index) => index !== idx)
      this.photoLinks = this.photoLinks.filter((element, index) => index !== idx)
    },
    closeShowSnackbarCopyNotSupported() {
      this.showSnackbarCopyNotSupported = false
    },
    launchFilePicker() {
      this.$refs.file.value = ''
      this.$refs.file.click()
    },
    openFileManagerMsg() {
      fileManagerModal(fileManagerOptions(this.user.sub_id), this.saveFile)
    },
    async saveFile(data, picker) {
      // console.log('sayang data', data)
      this.isLoadingImage = true
      let formData = new FormData()
      let headers = new Headers()
      let formatImage = [
        '.jpg',
        '.jpeg',
        '.png',
        '.gif',
        '.bmp',
        '.tiff',
        '.tif',
        '.webp',
        '.svg',
        '.raw',
        '.cr2',
        '.nef',
        '.arw',
      ]
      const regexPattern = new RegExp(formatImage.join('|'))
      formData.append('sub_id', this.user.sub_id)

      for (let i = 0; i < data.length; i++) {
        const file = data[i]

        if (picker === 'filePicker') {
          const fileURL = URL.createObjectURL(file)
          const blobFile = await fetch(fileURL).then(res => res.blob())
          const typeIndex = file.name.lastIndexOf('.')
          formData.append('files', blobFile, file.name)
        } else {
          formData.append('urls', `https://fm.prod.marketa.id/uploads/${file.uploadRelativePath}`)
        }
      }

      try {
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        }

        let { data: imageData } = await this.$axios.post(`https://file-upload.prod.marketa.id/upload`, formData, config)
        console.log('sayang log imagedata', imageData)

        if (imageData.data.failed.length) {
          imageData.data.failed.forEach(el => {
            if (el.mimeType === 'text/csv') {
              el.preview = 'https://cdn-icons-png.flaticon.com/512/8242/8242984.png'
            } else if (el.mimeType.includes('spreadsheetml') || el.uploadRelativePath.includes('xlsx')) {
              el.preview = '/file-manager/icons/application-vnd.ms-excel.svg'
            } else if (el.mimeType === 'application/pdf' || el.uploadRelativePath.includes('pdf')) {
              el.preview = '/file-manager/icons/application-pdf.svg'
            } else if (el.mimeType.includes('video')) {
              el.preview = 'https://fm.prod.marketa.id/unsafe/200x0/'+el.uploadURL.replace(/^https?:\/\//, '')
              // el.preview = `https://upload.wikimedia.org/wikipedia/commons/thumb/1/1d/Gnome-video-x-generic.svg/2048px-Gnome-video-x-generic.svg.png`
            } else if (el.mimeType === 'text/plain') {
              el.preview = 'https://cdn-icons-png.flaticon.com/512/3979/3979306.png'
            } else if (el.mimeType.includes('document')) {
              el.preview = '/file-manager/icons/application-msword-template.svg'
            } else if (regexPattern.test(el.uploadRelativePath)) {
              el.preview = 'https://fm.prod.marketa.id/unsafe/200x0/'+el.uploadURL.replace(/^https?:\/\//, '')
              // el.preview = el.uploadURL
            } else {
              el.preview = '/file-manager/icons/text.svg'
            }
          })
          this.photoLinks = [...this.photoLinks, ...imageData.data.failed]
          this.formAddTemplate.attachments = [...this.formAddTemplate.attachments, ...imageData.data.failed]
        } else {
          imageData.data.uploaded.forEach(el => {
            if (el.mimeType === 'text/csv') {
              el.preview = 'https://cdn-icons-png.flaticon.com/512/8242/8242984.png'
            } else if (el.mimeType.includes('spreadsheetml') || el.uploadRelativePath.includes('xlsx')) {
              el.preview = '/file-manager/icons/application-vnd.ms-excel.svg'
            } else if (el.mimeType === 'application/pdf' || el.uploadRelativePath.includes('pdf')) {
              el.preview = '/file-manager/icons/application-pdf.svg'
            } else if (el.mimeType.includes('video')) {
              el.preview = 'https://fm.prod.marketa.id/unsafe/200x0/'+el.uploadURL.replace(/^https?:\/\//, '')
              // el.preview = `https://upload.wikimedia.org/wikipedia/commons/thumb/1/1d/Gnome-video-x-generic.svg/2048px-Gnome-video-x-generic.svg.png`
            } else if (el.mimeType === 'text/plain') {
              el.preview = 'https://cdn-icons-png.flaticon.com/512/3979/3979306.png'
            } else if (el.mimeType.includes('document')) {
              el.preview = '/file-manager/icons/application-msword-template.svg'
            } else if (regexPattern.test(el.uploadRelativePath)) {
              el.preview = 'https://fm.prod.marketa.id/unsafe/200x0/'+el.uploadURL.replace(/^https?:\/\//, '')
              // el.preview = el.uploadURL
            } else {
              el.preview = '/file-manager/icons/text.svg'
            }
          })
          this.photoLinks = [...this.photoLinks, ...imageData.data.uploaded]
          this.formAddTemplate.attachments = [...this.formAddTemplate.attachments, ...imageData.data.uploaded]
        }
        this.isLoadingImage = false
      } catch (error) {
        console.log(error)
      }
    },
    addButtonLink() {
      if (this.labelLink && this.valueLink) {
        if (!this.formAddTemplate.listButton) this.formAddTemplate.listButton = []
        this.formAddTemplate.listButton.push({
          label: this.labelLink,
          urlButton: {
            displayText: this.labelLink,
            url: `https://${this.valueLink}`,
          },
        })
        this.labelLink = ''
        this.valueLink = ''
      }
      this.resetIndex()
    },
    addButtonCall() {
      if (this.labelCall && this.valueCall) {
        if (!this.formAddTemplate.listButton) this.formAddTemplate.listButton = []
        this.formAddTemplate.listButton.push({
          label: this.labelCall,
          callButton: {
            displayText: this.labelCall,
            phoneNumber: this.valueCall,
          },
        })
        this.labelCall = ''
        this.valueCall = ''
      }
      this.resetIndex()
    },
    addButtonReply() {
      if (this.labelReply) {
        if (!this.formAddTemplate.listButton) this.formAddTemplate.listButton = []
        this.formAddTemplate.listButton.push({
          label: this.labelReply,
          quickReplyButton: {
            displayText: this.labelReply,
            id: 'id-like-buttons-message2',
          },
        })
        this.labelReply = ''
        this.valueReply = ''
      }
      this.resetIndex()
    },
    removeButton(index) {
      this.formAddTemplate.listButton.splice(index, 1)
      this.resetIndex()
    },
    resetIndex() {
      this.formAddTemplate.listButton.forEach((btn, index) => {
        this.formAddTemplate.listButton[index].index = index + 1
      })
    },
    addEmoji(event) {
      insertTextAtCursor(document.querySelector('#messageText'), event.detail.unicode)
    },
    addText(text) {
      insertTextAtCursor(document.querySelector('#messageText'), text)
    },
    addTrackerLink(link) {
      if (this.formAddTemplate.message[this.formAddTemplate.message.length - 1] === ' ') {
        insertTextAtCursor(
          document.querySelector('#messageText'),

          `${this.linkWaRediurect}/track/${link}/{{phone_number}}/{{name}}`,
        )
      } else {
        insertTextAtCursor(
          document.querySelector('#messageText'),
          ` ${this.linkWaRediurect}/track/${link}/{{phone_number}}/{{name}}`,
        )
      }
    },
    addGeneratorLink(link) {
      if (this.formAddTemplate.message[this.formAddTemplate.message.length - 1] === ' ') {
        insertTextAtCursor(
          document.querySelector('#messageText'),

          `${this.linkWaRediurect}/${link}`,
        )
      } else {
        insertTextAtCursor(document.querySelector('#messageText'), ` ${this.linkWaRediurect}/${link}`)
      }
    },
    closeDialog() {
      this.dialog.addMessageTEmplate = false
      this.propsUppyUploaderReply = null
      this.propsUppyUploader = null
      this.formAddTemplate = {
        template_name: '',
        category: {},
        language: '',
        message: '',
        quick_reply: false,
        link_attachment: [],
        last_update: '',
        attachments: [],
        contact_list: '',
        id: '',
        on_reply: false,
        on_reply_message: {},
      }

      this.on_reply_message = {
        message: '',
        attachments: [],
      }
      this.openAttachment = false
    },
    reloadMustache() {
      this.mustacheList = this.mustacheAll[this.contactList.value] || []
      this.formAddTemplate.contact_list = { ...this.contactList }
    },

    // get category
    // async getDataCategory() {
    //   this.looading.category = true
    //   if (!this.pouchSettings) {
    //     await this.connectSettings(false)
    //   }

    //   const categoryTemplate = await this.$store.dispatch('global/getSettingsByKey', {
    //     sub_id: this.user.sub_id,
    //     token: this.user.token,
    //     key: 'category_message',
    //   })

    //   if (categoryTemplate.status) {
    //     this.listTemplate = categoryTemplate.data === null ? [] : categoryTemplate.data.value
    //   }

    //   //console.log(this.listTemplate, `INI TEMPLATE`)

    //   // const data = await this.loadSettingsById('category_message', this.serverConnectionStatus)
    //   // this.listCategory = data.value
    //   this.looading.category = false
    // },

    async getDataCategory(remote = false) {
      this.looading.category = true
      let foundCategory = await clientPocketBase.collection('crm_message_template_categories').getFullList(400, {
        $autoCancel: false,
      })
      this.listCategory = [...foundCategory, { id: 'add-category-option', name: '+ Add Category' }]
      this.looading.category = false
    },

    checkExtraField() {
      // Define regular expression to match extra fields
      const extraFieldRegex = /{{\s*(\w+)\s*}}/g

      // Extract extra fields from message
      const extraFields = []
      let match
      while ((match = extraFieldRegex.exec(this.formAddTemplate.message)) !== null) {
        extraFields.push(match[1])
      }

      // Check if there are any extra fields
      if (extraFields.length > 0) {
        this.formAddTemplate.extraField = true
      } else {
        this.formAddTemplate.extraField = false
      }
    },
    async saveMessageTemplate() {
      // Update formAddTemplate with extra fields and other data
      this.formAddTemplate.id = uuidv4()
      this.formAddTemplate._id = this.$nanoid()
      this.formAddTemplate.last_update = moment().format('DD-MM-YYYYTHH:mm:ss')
      this.formAddTemplate.on_reply_message = this.on_reply_message
      this.formAddTemplate.sub_id = this.user.sub_id
      this.formAddTemplate.id = this.$Md5Hash(`${this.user.sub_id}-${this.formAddTemplate._id}`)
      this.formAddTemplate.category = this.formAddTemplate.category?.id

      if (this.on_reply_message.message === '' && this.propsUppyUploaderReply === null) {
        this.formAddTemplate.on_reply = false
      } else {
        this.formAddTemplate.on_reply = true
      }

      if (this.user.role !== 'Admin') {
        this.formAddTemplate.operator = this.user.email
      }

      // Check if there are any extra fields
      this.checkExtraField()
      // this.formAddTemplate.quick_reply = true
      console.log('masuk sini gak<')
      const validateParent = this.$refs['form-add-template-parent'].validate()
      const validate = this.$refs['form-add-template'].validate()
      try {
        if (validate && validateParent && this.formAddTemplate.category) {
          const createMessageTemplate = await clientPocketBase
            .collection('crm_message_templates')
            .create(this.formAddTemplate)
          await this.mixinSaveTemplate(this.formAddTemplate, this.user)
          if (this.formAddTemplate.quick_reply) {
            // Create a new saved reply in PocketBase
            try {
              await clientPocketBase.collection('crm_quick_reply').create({
                tag: this.formAddTemplate.template_name,
                text: this.formAddTemplate.message,
                sub_id: this.user.sub_id,
                user_id: this.user.id,
                is_global: this.user.role === 'Admin' ? true : false,
                messages_templates: createMessageTemplate.id,
              })
            } catch (error) {
              console.log(error, 'error create quick reply')
            }
            // this.$emit('close-dialog', this.formAddTemplate)
            // console.log('masuk 6')
            // this.resetForm()
          }
          this.$emit('close-dialog', this.formAddTemplate)
          this.resetForm()
        }
      } catch (error) {
        console.log(error, 'err save')
      }
    },
    resetForm() {
      this.dialog.addMessageTEmplate = false
      this.propsUppyUploaderReply = null
      this.propsUppyUploader = null
      this.photoLinks = []
      this.formAddTemplate = {
        template_name: '',
        category: {},
        language: '',
        message: '',
        quick_reply: false,
        link_attachment: [],
        last_update: '',
        attachments: [],
        contact_list: '',
        id: '',
        on_reply: false,
        on_reply_message: {},
        listButton: [],
      }

      this.on_reply_message = {
        message: '',
        attachments: [],
      }
    },
    async editMessageTemplate() {
      this.formAddTemplate.on_reply_message = this.on_reply_message

      // this.formAddTemplate.category = this.formAddTemplate.category.id
      if (this.formAddTemplate.category?.id) {
        this.formAddTemplate.category = this.formAddTemplate.category.id
      }

      // Check if there are any extra fields
      this.checkExtraField()

      if (this.on_reply_message.message === '' && this.propsUppyUploaderReply === null) {
        this.formAddTemplate.on_reply = false
      } else {
        this.formAddTemplate.on_reply = true
      }
      const validateParent = this.$refs['form-add-template-parent'].validate()
      const validate = this.$refs['form-add-template'].validate()
      if (validate && validateParent && this.formAddTemplate.category) {
        await clientPocketBase
          .collection('crm_message_templates')
          .update(this.formAddTemplate.id, this.formAddTemplate, {
            $autoCancel: false,
          })
        await this.mixinEditTemplate(this.formAddTemplate, this.user)

        try{
          const foundQuickReply = await clientPocketBase
            .collection('crm_quick_reply')
            .getFirstListItem(`messages_templates = '${this.formAddTemplate.id}'`)
          await clientPocketBase.collection('crm_quick_reply').update(foundQuickReply.id, {
            tag: this.formAddTemplate.template_name,
            text: this.formAddTemplate.message,
          })
        }catch(err){
          // console.log(e)
        }

        this.$emit('close-dialog')
        this.resetForm()
      }
    },

    // get for edit message template
    // getMessageTemplate(dataEdit, index, show = false) {
    //   //console.log('🚀 ~ file: message_template.vue ~ line 930 ~ getMessageTemplate ~ dataEdit', dataEdit)
    //   this.getDataCategory()
    //   this.dialog.addMessageTEmplate = true
    //   ;(this.formAddTemplate.listButton = dataEdit.listButton ? dataEdit.listButton : []),
    //     (this.formAddTemplate.id = dataEdit.id)
    //   this.formAddTemplate.message = dataEdit.message
    //   this.formAddTemplate.template_name = dataEdit.template_name
    //   this.formAddTemplate.category = dataEdit.category
    //   this.formAddTemplate.last_update = moment().format('DD-MM-YYYYTHH:mm:ss')
    //   this.formAddTemplate.attachments = dataEdit.attachments
    //   this.formAddTemplate.contact_list = dataEdit.contact_list || { ...this.contactList }
    //   this.contactList = dataEdit.contact_list || { ...this.contactList }
    //   this.mustacheList = this.mustacheAll[dataEdit.contact_list.value] || []
    //   this.propsUppyUploader = dataEdit
    //   this.status_message_template = 'edit'
    //   console.log('masuk sini nyund')
    //   this.dataIndex = index
    //   this.dataIndexOnreply = index
    //   this.extrafieldName = dataEdit.extraField
    //   this.on_reply_message.message = dataEdit.on_reply_message.message
    //   this.propsUppyUploaderReply = dataEdit.on_reply_message
    //   this.showTemplate = show
    //   if (!this.serverConnectionStatus) {
    //     this.uppyDashboardSettings.disabled = true
    //   } else this.uppyDashboardSettings.disabled = false
    // },
  },
}
</script>
<style scoped>
.height-dialog {
  max-height: 400px;
  overflow-y: auto;
}
.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-bottom: 15px;
}
.message-file-name {
  font-size: 12px;
  color: #000;
  width: 120px;
  white-space: normal; /* allow text to wrap */
  word-wrap: break-word; /* break long words if necessary */
  display: flex;
  justify-content: center;
  margin-top: 2px;
  text-align: center;
}
</style>
